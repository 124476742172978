import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { cleanAllData } from "../../Actions/ActionCreators";
import { NavLink } from "react-router-dom";
import { version_code } from "../../component/config/config"
const SideBar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logout = () => {
		dispatch(cleanAllData());
		localStorage.clear();
		navigate("/");
	};
	let { pathname } = useLocation();

	return (
		<>
			<div className="sidebar-L">
				<ul>
					<li>
						<NavLink to="/">
							<i className=" ri-dashboard-line"></i> Dashboard
						</NavLink>
					</li>
					<li>
						<NavLink to='myorder/open'>
							<i className="ri-shopping-cart-line"></i>My Orders
						</NavLink>
					</li>
					<li>
						<NavLink to="mymenu/all">
							<i className="ri-file-list-3-line"></i>My Menu
						</NavLink>
					</li>
					<li>
						<NavLink to="/dashboard/myearnings">
							<i className="ri-money-dollar-box-line"></i>My
							Earnings
						</NavLink>
					</li>
					{/* <li>
						<a >
							<i className="ri-money-dollar-box-line"></i>My
							Earnings
						</a>
					</li> */}
					<li>
						<NavLink to="/dashboard/myreviews" >
							<i className="ri-star-smile-line"></i>My Reviews
						</NavLink>
					</li>
					{/* <li>
						<a >
							<i className="ri-star-smile-line"></i>My Reviews
						</a>
					</li> */}
					<li>
						<NavLink to="cheffaq">
							<i className="ri-questionnaire-line"></i>Help Center
						</NavLink>
					</li>
					{/* <li>
						<a>
							<i className="ri-settings-2-line"></i>Settings
						</a>
					</li> */}
					<li>
						<NavLink to="settings">
							<i className="ri-settings-2-line"></i>Settings
						</NavLink>
					</li>
					<li onClick={() => logout()}>
						<a>
							<i className="ri-logout-box-line"></i> Logout
						</a>
					</li>
				</ul>
				<span className="versioncode" >
					v{version_code}
				</span>
			</div>
		</>
	);
};

export default SideBar;
