import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DishImage from "../../assets/images/dish-img.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { NewOrderURL } from "../../component/config/config";
import { toast } from "react-toastify";
import authHeader from "../services/auth_header";
import { tostS, tostE } from "../../toast";
import { ImageURL } from "../../component/config/config";
import moment from "moment";
import { AcceptOrderURL } from "../../component/config/config";
import { RejectOrderURL } from "../../component/config/config";
import { DashboardURL } from "../../component/config/config";
import axiosInstance from "../config/axios";

import { FormikProvider, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import Pagination from "../myOrders/Pagination";

const Dashboard = () => {
  const [newOrder, setNewOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(2);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sal, setSale] = useState([]);

  const navigate = useNavigate();

  const { chefDetails } = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const todaySale = async () => {
    try {
      const response = await axiosInstance().get(DashboardURL, {});
      setSale(response.data.data);
      // console.log(response.data.data)
    } catch (err) {
      console.log(err.response.data.message);
    }
  };
  const newOrderToday = async (cp, searchString) => {
    document.body.classList.add("loading-indicator");
    console.log(searchString);
    setLoading(true);
    try {
      const res = await axiosInstance().post(NewOrderURL, {
        pageSize: postsPerPage,
        pageNumber: cp,
        searchItem: searchString,
      });
      if (res.status === 200) {
        document.body.classList.remove("loading-indicator");
        setNewOrder(res.data.data);
        setTotalData(res.data.total_records);
        tostS(res.data.message);
      } else {
        document.body.classList.remove("loading-indicator");
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      const message1 = error.res.data.message;
      tostE(error.res.data.message);
    }
  };
  useEffect(() => {
    let p = new Promise((resolve, reject) => {
      if (
        chefDetails.addDishComplete === false &&
        chefDetails.chefDetailsComplete === true
      ) {
        navigate("/dashboard/adddish");
      } else if (chefDetails.chefDetailsComplete === false) {
        navigate("/dashboard/chefdetails");
      }
      resolve();
    });
    p.then(() => {});
    p.catch((err) => {});
  }, [chefDetails]);
  useEffect(() => {
    newOrderToday(currentPage);
    todaySale();
  }, []);

  const accept = (id) => {
    axiosInstance()
      .post(AcceptOrderURL, { id: id })
      .then((re) => {
        if (re.status === 200) {
          tostS(re.data.message);
          newOrderToday(currentPage);
        }
      })
      .catch((error) => {
        tostE(error.re.data.message);
      });
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = (ids) => {
    setOpen(true);
    setFieldValue("id", ids);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(5, " Reason must be at least 5 characters ")
      .max(750, "Reason cannot be more than 750 characters ")
      .required("Reason is required"),
  });
  const formik = useFormik({
    initialValues: {
      reason: "",
      id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, ids) => {
      try {
        const respon = await axiosInstance().post(RejectOrderURL, values, {
          id: ids,
        });
        console.log(respon);
        if (respon.status === 200) {
          const messa = respon.data.message;
          setOpen(false);
          newOrderToday(currentPage);
          tostS(messa);
        }
      } catch (error) {
        setIsDisabled(false);
        tostE(error.respon.data.messsage);
      }
    },
  });

  const paginate = (pageNumber) => {
    newOrderToday(pageNumber);
    setCurrentPage(pageNumber);
  };

  const onNext = () => {
    setCurrentPage(currentPage + 1);
    newOrderToday(currentPage + 1);
  };
  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
    newOrderToday(currentPage - 1);
  };

  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    values,
    setValues,
  } = formik;
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>dashboard</h3>
        </div>
        <div className="row dashboard-box-main">
          <div className="col-md-6 mb-3">
            <div className="dashboard-top-box purple-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalDishesServed}</b>
                <span>Total Dishes Served Till Date</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="dashboard-top-box pink-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalSales?.toFixed(2)}</b>
                <span>Total Earnings</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-money-dollar-circle-line"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-top-box pink-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalDishesForToday}</b>
                <span>Total Dishes on Menu Today</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-top-box purple-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalDishesForWeek}</b>
                <span>Total Dishes on Menu this Week</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-top-box pink-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalOrdersForWeek}</b>
                <span>Total Orders Completed so far this Week</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;