import React, { useEffect, useState } from "react";
import { CompletedOrderDetailURL } from "../../component/config/config";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { tostE } from "../../toast";
import moment from "moment";
import { ImageURL } from "../../component/config/config";
import axiosInstance from "../config/axios";

const ViewDetails = () => {
  const { id } = useParams();
  
  const [details, setDetails] = useState({});

  const location = useLocation();
  const orderDetails = async () => {
    try {
      const response = await axiosInstance().post(CompletedOrderDetailURL, {
        id: id,
      });
      let resp = response.data.data;
      setDetails(resp);
    } catch (error) {
      tostE(error.x.data.message);
    }
  };
  useEffect(() => {
    orderDetails();
  }, []);
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my orders</h3>
          <nav className="breadcrumb-box">
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item"><NavLink to="/dashboard/myorder/new">My Orders</NavLink></li> */}
              <li className="breadcrumb-item">
                <NavLink to="/dashboard/myorder/open">My Orders</NavLink>
              </li>
              {location.state.type === 'complete' ? (
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard/myorder/completed">
                    Completed Order
                  </NavLink>
                </li>
              ) : (
                <li className="breadcrumb-item">
                  <NavLink to="/dashboard/myorder/canceled">
                    Canceled Orders
                  </NavLink>
                </li>
              )}

              <li className="breadcrumb-item active" aria-current="page">
                {" "}
                Order Detail
              </li>
            </ol>
          </nav>
        </div>
        <div className="dash-bottom mt-2 my-order">
          <div className="dish-head">
            <div className="title-timer">
              <small>
                #{details?.orderId} <b> {details?.orderPortion}</b>
              </small>
            </div>
            <h3 className="my-orders-title pt-2">
              {moment(details?.placeOrderDate).format("Do MMM YYYY")}
            </h3>
          </div>

          <ul className="Dishes-list my-order-list">
            {details.dishData &&
              details.dishData.map((data, index) => (
                <li>
                  <figure className="Dishes-list-L">
                    <img src={ImageURL + data?.dishId?.dishImage} />
                  </figure>
                  <figcaption className="Dishes-list-R">
                    <h4>{data?.dishName}</h4>
                    <span className="pb-1 price">${data?.dishPrice}</span>
                    <br />
                    <span className="pb-2">
                      <b>Qty:</b> {data?.qty}
                    </span>
                    <br />
                    <span>
                      <b>Type:</b> {details?.orderType}{" "}
                    </span>
                  </figcaption>
                </li>
              ))}
          </ul>

          <hr className="grey-line" />

          <div className="food-info food-info-break my-order-food-info d-flex col-md-6">
            <span>
              <b>Delivery Preference </b>
              {details?.deliveryPreference}
            </span>
            <span>
              <b>Delivery / Pick up Window</b>
              {details?.deliveryOrPickupWindow}
            </span>
            <span>
              <b>Order {location.state.type === 'complete' ? 'Delivered': 'Cancelation'} Date</b>
              {location.state.type === 'complete' ? 
              moment(details?.orderDeliveryDate).format("LLL") : 
              moment(details?.cancelDate).format("LLL")}
            </span>
            <span className="full-food-info">
              <b>Order Instructions</b>
              {details?.orderInstructions}
            </span>
            <span>
              <b>Customer Name</b>
              {details?.userId?.fullName}
            </span>
            <span>
              <b>Customer Phone Number</b>
              {`(${details?.userId?.phoneNumber.slice(
                0,
                3
              )}) ${details?.userId?.phoneNumber.slice(
                3,
                6
              )}-${details?.userId?.phoneNumber.slice(6)} `}
            </span>
          </div>
        </div>
      </div>
      {/* <div className="screen-div-info">
                <ul className="info-list">
                    <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
                    <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
                </ul>
            </div> */}
    </>
  );
};

export default ViewDetails;
