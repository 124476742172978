// export const BASE = "https://api-stage.wannaeat.com";
//  export const BASE = "http://localhost:17283";
//  export const CDN_URL = "https://d1n1jmgqtuwwh9.cloudfront.net/";
//  export const WannaEatBase = "https://stage.wannaeat.com";

export const BASE = "https://api.wannaeat.com";
export const WannaEatBase = "https://www.wannaeat.com";
const CDN_URL = "https://d1rlj30nna74zx.cloudfront.net/";

export const version_code = "2.5.4";

export const ImageURL = CDN_URL;
const BASE_API = BASE + "/api/chef";

export const ExtensionChangeURL = BASE_API + "/public/access/imageUrl";
export const SignupURL = BASE_API + "/public/access/signUp";
export const ChefDetailsURL = BASE_API + "/public/access/addChefDetails";
export const UploadURL = BASE_API + "/public/access/uploadDocuments";
export const AddDishURL = BASE_API + "/public/access/addDish";
export const AddPaymentURL = BASE_API + "/public/access/addPaymentInfo";
export const FoodCategoryURL = BASE_API + "/public/access/getFoodCategory";
export const CuisinesURL = BASE_API + "/public/access/getCuisines";
export const LoginURL = BASE_API + "/public/access/login";
export const ForgotURL = BASE_API + "/public/access/forgotPassword";
export const ResetURL = BASE_API + "/public/access/resetPassword";
export const CreateURL = BASE_API + "/public/access/createPassword";
export const MyMenuURL = BASE_API + "/private/menu/getAllMenu";
export const AddishURL = BASE_API + "/private/menu/addDish";
export const ContactURL = BASE_API + "/public/access/contactUs";
export const EditDishURL = BASE_API + "/private/menu/updateDish";
export const ViewDishURL = BASE_API + "/private/menu/viewDish";
export const TagURL = BASE_API + "/public/access/getTags";
export const ChefURL = BASE_API + "/private/access/getChefDetails";
export const DishURL = BASE_API + "/public/access/getDishDetails";
export const DeleteURL = BASE_API + "/private/menu/deleteDish";
export const checkDeleteURL = BASE_API + "/private/menu/checkdeleteDish";
export const DishDetailsURL = BASE_API + "/private/menu/getDishesNames";
export const AddMenuDetailsURL = BASE_API + "/private/menu/addMenu";
export const MenuDatesURL = BASE_API + "/private/menu/getMenuDates";
export const MenuDatesDishesURL = BASE_API + "/private/menu/getMenuDateDishes";
export const ChefFAQ = BASE_API + "/private/helpCenter/getFaqs";
export const ChefContactUs = BASE_API + "/private/helpCenter/sendInquiry";
export const OpenOrderURL = BASE_API + "/private/orders/getOpenOrder";
export const NewOrderURL = BASE_API + "/private/orders/getNewOrder";
export const AcceptOrderURL = BASE_API + "/private/orders/acceptOrder";
export const RejectOrderURL = BASE_API + "/private/orders/rejectOrder";
export const CompletedOrderURL = BASE_API + "/private/orders/getCompletedOrder";
export const CompletedOrderDetailURL =
  BASE_API + "/private/orders/getOrderDetail";
export const CountryURL = BASE_API + "/public/access/getCountries";
export const AllChefReviews = BASE_API + "/private/review/getChefReviews";
export const AllDishReviews = BASE_API + "/private/review/getDishReviews";
export const ViewDetailedReview =
  BASE_API + "/private/review/getOneDishReviews";
export const OrderPrepareURL = BASE_API + "/private/orders/getOrderToPrepare";
export const UserFaqURL = BASE_API + "/public/access/getUserFaq";
export const ChefFaqURL = BASE_API + "/public/access/getChefFaq";
export const UpdateOrderURL = BASE_API + "/private/orders/updateOrderStatus";
export const PrivacyPolicyURL = BASE_API + "/public/access/getPrivacyPolicy";
export const TermsURL = BASE_API + "/public/access/getTermsOfService";
export const AboutURL = BASE_API + "/public/access/getAboutUs";
export const TotalURL = BASE_API + "/private/earnings/totalSales";
export const DashboardURL = BASE_API + "/private/dashboard/dashboardDetails";
export const PrintURL = BASE_API + "/private/orders/updatePrintLabelStatus";
export const UpdateMenuURL = BASE_API + "/private/menu/updateMenu";
export const DeleteMenuURL = BASE_API + "/private/menu/deleteDishFromMenu";
export const ChefTermsURL = BASE_API + "/private/access/getChefTermsOfService";
export const LandingURL = BASE_API + "/public/access/getLandingPage";
export const TestimonialURL = BASE_API + "/public/access/getTestimonialPage";
export const LoginChefURL = BASE_API + "/private/access/getLoggedInChefDetails";
export const CanceledOrderURL = BASE_API + "/private/orders/getCanceledOrder";
export const CateringlURL = BASE_API + "/public/access/getCateringPage";
export const dishClone = BASE_API + "/private/menu/cloneDish";

//server 2 api access

// export const SignupURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/signUp";
// export const ChefDetailsURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/addChefDetails";
// export const UploadURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/uploadDocuments";
// export const AddDishURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/addDish";
// export const AddPaymentURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/addPaymentInfo";
// export const FoodCategoryURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getFoodCategory";
// export const CuisinesURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getCuisines";
// export const LoginURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/login";
// export const ForgotURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/forgotPassword";
// export const ResetURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/resetPassword";
// export const CreateURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/createPassword";
// export const MyMenuURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/getAllMenu";
// export const AddishURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/addDish";
// export const ContactURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/contactUs";
// export const EditDishURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/updateDish";
// export const ViewDishURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/viewDish";
// export const TagURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getTags";
// export const ChefURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getChefDetails";
// export const DishURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getDishDetails";
// export const DeleteURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/deleteDish";
// /";
// export const DishDetailsURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/getDishesNames";
// export const AddMenuDetailsURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/addMenu";
// export const MenuDatesURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/getMenuDates";
// export const MenuDatesDishesURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/menu/getMenuDateDishes";
// export const ChefFAQ =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/helpCenter/getFaqs";
// export const ChefContactUs =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/helpCenter/sendInquiry";
// export const OpenOrderURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/getOpenOrder";
// export const NewOrderURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/getNewOrder";
// export const AcceptOrderURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/acceptOrder";
// export const RejectOrderURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/rejectOrder";
// export const CompletedOrderURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/getCompletedOrder";
// export const CompletedOrderDetailURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/getOrderDetail";
// export const CountryURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getCountries";
// export const AllChefReviews =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/review/getChefReviews";
// export const AllDishReviews =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/review/getDishReviews";
// export const ViewDetailedReview =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/review/getOneDishReviews";
// export const OrderPrepareURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/getOrderToPrepare";
// export const UserFaqURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getUserFaq";
// export const ChefFaqURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getChefFaq";
// export const UpdateOrderURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/orders/updateOrderStatus";
// export const PrivacyPolicyURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getPrivacyPolicy";
// export const TermsURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getTermsOfService";
// export const AboutURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/public/access/getAboutUs";
// export const TotalURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/earnings/totalSales";
// export const DashboardURL =
//     "https://dev-wannaeat.devtechnosys.tech:17369/api/chef/private/dashboard/dashboardDetails";

//server 1 api access

// export const SignupURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/signUp";
// export const ChefDetailsURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/addChefDetails";
// export const UploadURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/uploadDocuments";
// export const AddDishURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/addDish";
// export const AddPaymentURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/addPaymentInfo";
// export const FoodCategoryURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getFoodCategory";
// export const CuisinesURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getCuisines";
// export const LoginURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/login";
// export const ForgotURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/forgotPassword";
// export const ResetURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/resetPassword";
// export const CreateURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/createPassword";
// export const MyMenuURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/getAllMenu";
// export const AddishURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/addDish";
// export const ContactURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/contactUs";
// export const EditDishURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/updateDish";
// export const ViewDishURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/viewDish";
// export const TagURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getTags";
// export const ChefURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getChefDetails";
// export const DishURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getDishDetails";
// export const DeleteURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/deleteDish";
//
// export const DishDetailsURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/getDishesNames";
// export const AddMenuDetailsURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/addMenu";
// export const MenuDatesURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/getMenuDates";
// export const MenuDatesDishesURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/menu/getMenuDateDishes";
// export const ChefFAQ =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/helpCenter/getFaqs";
// export const ChefContactUs =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/helpCenter/sendInquiry";
// export const OpenOrderURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/getOpenOrder";
// export const NewOrderURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/getNewOrder";
// export const AcceptOrderURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/acceptOrder";
// export const RejectOrderURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/rejectOrder";
// export const CompletedOrderURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/getCompletedOrder";
// export const CompletedOrderDetailURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/getOrderDetail";
// export const CountryURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getCountries";
// export const AllChefReviews =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/review/getChefReviews";
// export const AllDishReviews =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/review/getDishReviews";
// export const ViewDetailedReview =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/review/getOneDishReviews";
// export const OrderPrepareURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/getOrderToPrepare";
// export const UserFaqURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getUserFaq";
// export const ChefFaqURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getChefFaq";
// export const UpdateOrderURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/updateOrderStatus";
// export const PrivacyPolicyURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getPrivacyPolicy";
// export const TermsURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getTermsOfService";
// export const AboutURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/public/access/getAboutUs";
// export const TotalURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/earnings/totalSales";
// export const DashboardURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/dashboard/dashboardDetails";
// export const PrintURL =
//     "https://wannaeat.devtechnosys.tech:17283/api/chef/private/orders/updatePrintLabelStatus";

//Local server api access

// export const SignupURL =
//     "http://172.16.2.250:17283/api/chef/public/access/signUp";
// export const ChefDetailsURL =
//     "http://172.16.2.250:17283/api/chef/public/access/addChefDetails";
// export const UploadURL =
//     "http://172.16.2.250:17283/api/chef/public/access/uploadDocuments";
// export const AddDishURL =
//     "http://172.16.2.250:17283/api/chef/public/access/addDish";
// export const AddPaymentURL =
//     "http://172.16.2.250:17283/api/chef/public/access/addPaymentInfo";
// export const FoodCategoryURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getFoodCategory";
// export const CuisinesURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getCuisines";
// export const LoginURL =
//     "http://172.16.2.250:17283/api/chef/public/access/login";
// export const ForgotURL =
//     "http://172.16.2.250:17283/api/chef/public/access/forgotPassword";
// export const ResetURL =
//     "http://172.16.2.250:17283/api/chef/public/access/resetPassword";
// export const CreateURL =
//     "http://172.16.2.250:17283/api/chef/public/access/createPassword";
// export const MyMenuURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/getAllMenu";
// export const AddishURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/addDish";
// export const ContactURL =
//     "http://172.16.2.250:17283/api/chef/public/access/contactUs";
// export const EditDishURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/updateDish";
// export const ViewDishURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/viewDish";
// export const TagURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getTags";
// export const ChefURL =
//     "http://172.16.2.250:17283/api/chef/private/access/getChefDetails";
// export const DishURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getDishDetails";
// export const DeleteURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/deleteDish";
// /";
// export const DishDetailsURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/getDishesNames";
// export const AddMenuDetailsURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/addMenu";
// export const MenuDatesURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/getMenuDates";
// export const MenuDatesDishesURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/getMenuDateDishes";
// export const ChefFAQ =
//     "http://172.16.2.250:17283/api/chef/private/helpCenter/getFaqs";
// export const ChefContactUs =
//     "http://172.16.2.250:17283/api/chef/private/helpCenter/sendInquiry";
// export const OpenOrderURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/getOpenOrder";
// export const NewOrderURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/getNewOrder";
// export const AcceptOrderURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/acceptOrder";
// export const RejectOrderURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/rejectOrder";
// export const CompletedOrderURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/getCompletedOrder";
// export const CompletedOrderDetailURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/getOrderDetail";
// export const CountryURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getCountries";
// export const AllChefReviews =
//     "http://172.16.2.250:17283/api/chef/private/review/getChefReviews";
// export const AllDishReviews =
//     "http://172.16.2.250:17283/api/chef/private/review/getDishReviews";
// export const ViewDetailedReview =
//     "http://172.16.2.250:17283/api/chef/private/review/getOneDishReviews";
// export const OrderPrepareURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/getOrderToPrepare";
// export const UserFaqURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getUserFaq";
// export const ChefFaqURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getChefFaq";
// export const UpdateOrderURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/updateOrderStatus";
// export const PrivacyPolicyURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getPrivacyPolicy";
// export const TermsURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getTermsOfService";
// export const AboutURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getAboutUs";
// export const TotalURL =
//     "http://172.16.2.250:17283/api/chef/private/earnings/totalSales";
// export const DashboardURL =
//     "http://172.16.2.250:17283/api/chef/private/dashboard/dashboardDetails";
// export const PrintURL =
//     "http://172.16.2.250:17283/api/chef/private/orders/updatePrintLabelStatus";
// export const UpdateMenuURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/updateMenu";
// export const DeleteMenuURL =
//     "http://172.16.2.250:17283/api/chef/private/menu/deleteDishFromMenu";
// export const ChefTermsURL =
//     "http://172.16.2.250:17283/api/chef/private/access/getChefTermsOfService";
// export const LandingURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getLandingPage";
// export const TestimonialURL =
//     "http://172.16.2.250:17283/api/chef/public/access/getTestimonialPage";
