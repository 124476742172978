import moment from "moment-timezone";

export const timezone = "America/New_York";
export const orderedDate = (changeDate, changeTime) => {
  let selectedTime = moment.tz(changeTime, timezone).format("HH:mm:ss");
  let selectedDay = moment.tz(changeDate, timezone).format("YYYY-MM-DD");
  let finalDate = `${selectedDay} ${selectedTime}`;
  return finalDate;
};

export const datefiltered = (date) => new Date(date).toLocaleString();
export const selectedDateFormat = (date) => moment(date).format("YYYY-MM-DD");
export const filterPassedTime = (time, currentDate = new Date()) => {
  const selectedDate = new Date(time);
  const date1 = new Date(time).setHours(15, 30, 1, 1);
  const date2 = currentDate.getTime();
  return date1 >= date2;
};
export function getDateForMenu(dateStr = null) {
  return moment.tz(dateStr, timezone).toDate();
}
